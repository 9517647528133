import { acquireAccessToken, MenuAppBar, parseJwt, useWtxLocalization, NotFoundPage } from '@wavetronix/common-components'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { env } from '../index.js'
import DocumentViewerPage from './pages/DocumentViewerPage'
import { Redirect } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import UsersAccessPage from './pages/UsersAccessPage'
import DocumentsPage from './DocumentsPage'
import CategoryManagementPage from './pages/CategoryManagementPage'
import CrmProductsManagementPage from './pages/CrmProductsManagementPage'
import ProductViewerPage from './pages/ProductViewerPage'
import version from '../env/version.json'
import NexusAssetsPage from './NexusAssets/NexusAssetsPage'
const drawerWidth = 300

const classes = {
  paper: {
    width: drawerWidth
  },
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  title: {
    fontFamily: 'Klavika',
    fontSize: 26
  },
  inlineTitle: {
    fontFamily: 'Klavika',
    fontSize: '26px',
    display: 'inline'
  },
  pageHeader: {
    marginLeft: '24px',
    marginTop: '30px',
    fontSize: '24px',
    flex: 1,
    display: 'inline',
    justifyContent: 'left'
  },
  tableRow: {
    cursor: 'pointer'
  }
}

export const URL_MAP = {
  products: 0,
  documents: 1,
  users: 2,
  categories: 3,
  crmproducts: 4,
  assets: 5,
  '': 0
}

function MainPage() {
  const { instance, accounts } = useMsal()
  const localizedStrings = useWtxLocalization()
  const [uploadToken, setUploadToken] = useState('')
  const [state, setState] = useState({
    current: window.location.pathname.substring(1)
  })
  const [productState, setProductState] = useState({
    current: ''
  })

  document.getElementById('title').innerHTML = localizedStrings.title

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }
    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      resetUploadToken()
    }
  }, [instance, accounts, uploadToken])

  return (
    <div>
      <MenuAppBar env={env} fromCompany={env.basicAuthentication.fromCompany} />
      <div className='content'>
        <BrowserRouter>
          <Switch>
            <Route path='/' exact>
              <Redirect to='documents' />
            </Route>
            <Route
              path='/documents'
              exact
              render={() => (
                <DocumentsPage
                  styles={classes}
                  tabState={state}
                  changeTabState={s => {
                    setState(s)
                  }}
                  showFullList={false}
                  productState={productState}
                  setProductState={setProductState}
                />
              )}
            />
            <Route
              path='/search'
              exact
              render={() => (
                <DocumentsPage
                  styles={classes}
                  tabState={state}
                  changeTabState={s => {
                    setState(s)
                  }}
                  showFullList={true}
                  productState={productState}
                  setProductState={setProductState}
                />
              )}
            />
            <Route path='/details' exact render={() => <DocumentViewerPage styles={classes} />} />
            <Route path='/byproduct' exact render={() => <ProductViewerPage styles={classes} />} />
            <Route
              path='/users'
              exact
              render={() => <UsersAccessPage styles={classes} tabState={state} changeTabState={s => setState(s)} />}
            />
            <Route
              path='/categories'
              exact
              render={() => <CategoryManagementPage styles={classes} tabState={state} changeTabState={s => setState(s)} />}
            />
            <Route
              path='/crmproducts'
              exact
              render={() => <CrmProductsManagementPage styles={classes} tabState={state} changeTabState={s => setState(s)} />}
            />
            <Route
              path='/assets'
              exact
              render={() => <NexusAssetsPage styles={classes} tabState={state} changeTabState={s => setState(s)} />}
            />
            <Route path='/version' exact render={() => <div>{version.version}</div>} />
            {/* This wildcard route needs to stay at the bottom */}
            <Route path='*' component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  )
}
export default MainPage
