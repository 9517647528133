import * as React from 'react'
import {
  CenteredDiv,
  CustomSelect,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  useWtxLocalization,
  SlideUpDialog,
  DeleteButton,
  CustomAccordion,
  CustomCheckbox
} from '@wavetronix/common-components'
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { TextField, DialogContent, Grid, IconButton, Box, Divider } from '@mui/material'
import { useSnackbar } from 'notistack'
import CrmProductsApi from '../../api/CrmProductsApi'
import { DeleteOutline } from '@mui/icons-material'
import { AddOutlined } from '@mui/icons-material'

export default function EditCrmProductModal(props) {
  const { instance, accounts } = useMsal()
  const [crmId, setCrmId] = useState('')
  const [crmName, setCrmName] = useState('')
  const [crmFamily, setCrmFamily] = useState('')
  const [crmIsArchived, setCrmIsArchived] = useState(false)
  const [crmListOrder, setCrmListOrder] = useState(1)
  const [crmCompany, setCrmCompany] = useState('wavetronix')
  const [crmProducts, setCrmProducts] = useState([])
  const [newCrmProducts, setNewCrmProducts] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isUploading, setIsUploading] = useState(false)

  let localizedStrings = useWtxLocalization()

  useEffect(() => {
    if (props.current) {
      setCrmId(props.current.id)
      setCrmName(props.current.name)
      setCrmFamily(props.current.family)
      setCrmListOrder(props.current.listOrder)
      setCrmProducts(props.current.productItemNumbers)
      setCrmIsArchived(props.current.isArchived)
      setCrmCompany(props.current.fromCompany)
    }
  }, [props])

  function onClose() {
    props.refetch()
    props.onClose()
  }

  function buildCrm() {
    const crm = {
      id: crmId,
      name: crmName,
      family: crmFamily,
      listOrder: crmListOrder,
      productItemNumbers: crmProducts,
      isArchived: crmIsArchived,
      fromCompany: crmCompany,
      onCreatedTickets: props.current.onCreatedTickets
    }
    return crm
  }

  const deleteCrmProduct = async id => {
    await CrmProductsApi.deleteCrmProduct(instance, accounts, id).then(
      result => {
        enqueueSnackbar('CRM Product deleted.', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`CRM Product deletion fail - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  const createCrmProduct = async crm => {
    await CrmProductsApi.createCrmProduct(instance, accounts, crm).then(
      result => {
        enqueueSnackbar(`CRM Product ${crm.name} created.`, SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`CRM Product ${crm.name} creation fail - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  const updateCrm = async crm => {
    await CrmProductsApi.updateCrmProduct(instance, accounts, crm).then(
      _ => {
        enqueueSnackbar('CRM Product updated', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`CRM Product update fail - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  async function updateCreateCrmProduct() {
    if (props.current) {
      await updateCrm(buildCrm())
    } else {
      await createCrmProduct(buildCrm())
    }
  }

  async function duplicateCrmProducts() {
    let tempCRMProduct = buildCrm()
    let key = enqueueSnackbar('Creating CRM Products from template...', SnackbarVariants.LOADING)
    for (let prod of newCrmProducts) {
      const prodList = prod.productItemNumbers.replace(/\s/g, '').split(',')
      const newCRM = {
        ...tempCRMProduct,
        name: prod.name,
        productItemNumbers: prodList,
        id: ''
      }
      await createCrmProduct(newCRM)
    }
    closeSnackbar(key)
  }

  return (
    <SlideUpDialog
      id='editCRMProductModal'
      maxWidth={'lg'}
      fullScreen
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>{props.current ? 'Update CRM Product' : 'Create CRM Product'}</h3>}
      actions={
        <>
          <DeleteButton
            id='deleteCrmButton'
            style={{ marginLeft: 15 }}
            onClick={async e => {
              setIsUploading(true)
              await deleteCrmProduct(crmId)
              setIsUploading(false)
              onClose()
            }}
          >
            Delete CRM Product
          </DeleteButton>
          <div style={{ flex: '1 0 0' }} />
          <PrimaryButton
            id='updateCrmButton'
            style={{ marginRight: 15 }}
            disabled={!(crmName && crmFamily && crmListOrder)}
            onClick={async () => {
              setIsUploading(true)
              await updateCreateCrmProduct()
              setIsUploading(false)
              onClose()
            }}
          >
            {props.current ? 'Update' : 'Create'}
          </PrimaryButton>
        </>
      }
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <CenteredDiv>
              <CustomSelect
                id='crmCompanySelect'
                style={{ width: '100%', marginTop: 15 }}
                label={'Company'}
                required={true}
                value={crmCompany}
                onChange={e => setCrmCompany(e.target.value)}
                options={['wavetronix', 'islandradar', 'fathom']}
              />
            </CenteredDiv>
            <CenteredDiv>
              <CustomSelect
                id='crmFamilySelect'
                style={{ width: '100%', marginTop: 15 }}
                label={localizedStrings.productFamily}
                required={true}
                disabled={isUploading}
                value={crmFamily}
                onChange={e => setCrmFamily(e.target.value)}
                options={props.categoryFamlies.map(p => p.name)}
              />
            </CenteredDiv>
            <CenteredDiv>
              <TextField
                id='crmNameTextField'
                disabled={isUploading}
                label={localizedStrings.name}
                variant='outlined'
                style={{ marginTop: '15px', width: '100%' }}
                onChange={e => setCrmName(e.target.value)}
                required
                value={crmName}
              />
              <TextField
                id='crmListOrderTextField'
                inputProps={{ min: 1 }}
                type='number'
                disabled={isUploading}
                variant='outlined'
                style={{ marginTop: '15px', marginLeft: '10px', width: '20%' }}
                onChange={e => setCrmListOrder(e.target.value)}
                label={localizedStrings.listOrder}
                required
                value={crmListOrder}
              />
            </CenteredDiv>

            <Box sx={{ display: 'flex', direction: 'row' }}>
              <CustomCheckbox
                id='crmArchivedCheckbox'
                checked={crmIsArchived}
                onChange={event => setCrmIsArchived(event.target.checked)}
              />
              <span style={{ paddingTop: '9px' }}>Archive Crm Product</span>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div style={{ marginTop: '15px' }}>
              <CenteredDiv>
                <CustomAccordion defaultExpanded disableGutters title={'Products'} detailStyle={{ margin: '10px' }}>
                  {crmProducts && crmProducts.length > 0 ? (
                    crmProducts.map((itemNumber, index) => {
                      return (
                        <Box key={index} sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px' }}>
                          <TextField
                            id={`${index}ItemNumberTextField`}
                            error={itemNumber === ''}
                            sx={{ width: '90%' }}
                            size='small'
                            value={itemNumber}
                            onChange={e => {
                              let productCopy = [...crmProducts]
                              productCopy[index] = e.target.value
                              setCrmProducts([...productCopy])
                            }}
                          />
                          <Box sx={{ flex: '1 1 auto' }} />
                          <IconButton
                            id={`${index}ItemNumberRemoveButton`}
                            onClick={() => {
                              let productCopy = crmProducts.filter(prod => prod !== itemNumber)
                              setCrmProducts([...productCopy])
                            }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Box>
                      )
                    })
                  ) : (
                    <></>
                  )}
                  <CenteredDiv>
                    <RegularButton
                      id='crmAddItemNumberButton'
                      style={{ marginTop: '15px' }}
                      startIcon={<AddOutlined />}
                      onClick={() => {
                        let productCopy = [...crmProducts]
                        productCopy.push('')
                        setCrmProducts([...productCopy])
                      }}
                    >
                      Product Number
                    </RegularButton>
                  </CenteredDiv>{' '}
                </CustomAccordion>
              </CenteredDiv>
            </div>
            <Divider sx={{ marginTop: '15px' }} />
            <Box sx={{ marginTop: '15px', display: 'flex' }}>
              <CustomAccordion
                defaultExpanded
                disableGutters
                title={'Create CRM Product with current as template:'}
                detailStyle={{ margin: '10px' }}
              >
                {newCrmProducts ? (
                  newCrmProducts.map((productInfo, index) => {
                    return (
                      <Box key={index} sx={{ display: 'flex', width: '100%', marginTop: '5px' }}>
                        <TextField
                          id={`${index}CrmCopyNameTextField`}
                          disabled={isUploading}
                          label={'Name'}
                          variant='outlined'
                          style={{ width: '100%' }}
                          onChange={e => {
                            let productCopy = [...newCrmProducts]
                            productCopy[index].name = e.target.value
                            setNewCrmProducts([...productCopy])
                          }}
                          required
                          value={productInfo.name}
                        />
                        <TextField
                          id={`${index}CrmCopyItemNumbersTextField`}
                          disabled={isUploading}
                          label={'Product Item Numbers (comma seperated)'}
                          variant='outlined'
                          style={{ width: '100%', marginLeft: '5px' }}
                          onChange={e => {
                            let productCopy = [...newCrmProducts]
                            productCopy[index].productItemNumbers = e.target.value
                            setNewCrmProducts([...productCopy])
                          }}
                          required
                          value={productInfo.productItemNumbers}
                        />
                        <Box sx={{ flex: '1 1 auto' }} />
                        <IconButton
                          id={`${index}CrmCopyRemoveButton`}
                          onClick={() => {
                            let productCopy = newCrmProducts.filter(prod => prod.name !== productInfo.name)
                            setNewCrmProducts([...productCopy])
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Box>
                    )
                  })
                ) : (
                  <></>
                )}
                <CenteredDiv>
                  <RegularButton
                    id='crmCopyAddButton'
                    style={{ marginTop: '15px' }}
                    startIcon={<AddOutlined />}
                    onClick={() => {
                      let productCopy = [...newCrmProducts]
                      productCopy.push({ name: '', productItemNumbers: '' })
                      setNewCrmProducts([...productCopy])
                    }}
                  >
                    CRM Product
                  </RegularButton>
                </CenteredDiv>{' '}
                <Divider sx={{ marginTop: '15px' }} />
                <CenteredDiv>
                  <PrimaryButton
                    id='crmCopyCreateButton'
                    disabled={
                      !(crmName && crmFamily && crmListOrder) ||
                      isUploading ||
                      !newCrmProducts ||
                      newCrmProducts.length <= 0 ||
                      newCrmProducts.some(prod => prod.name === '' || prod.productItemNumbers === '')
                    }
                    style={{ marginTop: '15px' }}
                    onClick={duplicateCrmProducts}
                  >
                    Create CRM Products
                  </PrimaryButton>
                </CenteredDiv>
              </CustomAccordion>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </SlideUpDialog>
  )
}
