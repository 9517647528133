import { TextField } from '@mui/material'
import { useWtxLocalization, CustomAccordion, ListSelector } from '@wavetronix/common-components'
import { containsSubstring } from '../../utils/stringUtils'
import { useEffect } from 'react'
import { env } from '../../index.js'
export const DEFAULT_USERS_FILTER = {
  firstName: '',
  lastName: '',
  email: '',
  showOverrides: false,
  tags: []
}

export const filterUsers = (filter, users) => {
  return users
    ? users.filter(
        u =>
          (containsSubstring(u.givenName, filter.firstName) || filter.firstName === '') &&
          (containsSubstring(u.surname, filter.lastName) || filter.lastName === '') &&
          (containsSubstring(u.email, filter.email) || filter.email === '') &&
          (!u.tags || filter.tags.length === 0 || u.tags.some(t => filter.tags.map(g => g.id).includes(t)) || false)
      )
    : []
}
export const COMPANY_TAGS = [
  { value: 'Wavetronix', id: 'wavetronix' },
  { value: 'Island Radar', id: 'islandradar' },
  { value: 'Fathom', id: 'fathom' }
]
export default function UserFilterDrawer({ filter, setFilter }) {
  let localizedStrings = useWtxLocalization()
  useEffect(() => {
    setFilter(f => ({ ...f, tags: COMPANY_TAGS.filter(t => t.id === env.basicAuthentication.fromCompany) }))
  }, [setFilter])
  return (
    <>
      <TextField
        id='filterFirstNameTextField'
        style={{ width: '100%' }}
        label={localizedStrings.firstName}
        size='small'
        value={filter.firstName}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, firstName: e.target.value }))}
      />
      <TextField
        id='filterLastNameTextField'
        style={{ marginTop: '10px', width: '100%' }}
        label={localizedStrings.lastName}
        size='small'
        value={filter.lastName}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, lastName: e.target.value }))}
      />
      <TextField
        id='filterEmailTextField'
        style={{ marginTop: '10px', width: '100%' }}
        label={localizedStrings.email}
        size='small'
        value={filter.email}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, email: e.target.value }))}
      />
      <CustomAccordion title={'Company Tags'} style={{ marginTop: '15px' }} defaultExpanded>
        <ListSelector selectedOptions={filter.tags} options={COMPANY_TAGS} onChange={e => setFilter({ ...filter, tags: e })} />
      </CustomAccordion>
    </>
  )
}
