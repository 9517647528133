import * as React from 'react'
import { DialogContent, TextField } from '@mui/material'
import {
  CenteredDiv,
  CustomSelect,
  parseJwt,
  PrimaryButton,
  SnackbarVariants,
  useWtxLocalization,
  acquireAccessToken,
  SlideUpDialog,
  getResourceUrl
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { env } from '../../index.js'
import CategoriesApi from '../../api/CategoriesApi'
import FileUploader from '../controls/FileUploader'
import { useQuery } from '@tanstack/react-query'
import ImageSelect from '../NexusAssets/ImageSelect'
import NexusAssetsApi from '../../api/NexusAssetsApi'
import CrmProductsApi from '../../api/CrmProductsApi'

const classes = {
  paper: {
    position: 'absolute',
    width: '50%',
    height: '50%',
    backgroundColor: 'white',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '16px 32px 24px'
  },
  outside: {
    backgroundColor: 'white'
  },
  fileMetadata: {
    width: '100%',
    marginTop: '15px'
  }
}

export default function AddCategoryModal(props) {
  const { instance, accounts } = useMsal()
  const [categoryType, setCategoryType] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [categoryDescription, setCategoryDescription] = useState('')
  const [categoryFamily, setCategoryFamily] = useState('')
  const [categoryOrder, setCategoryOrder] = useState(1)
  const [categoryListOrder, setCategoryListOrder] = useState(1)
  const [uploadToken, setUploadToken] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [file, setFile] = useState(null)
  const [previewUrl, setPreviewUrl] = useState('')
  const [categoryImage, setCategoryImage] = useState('')

  let localizedStrings = useWtxLocalization()

  const { data: assetsData } = useQuery({
    queryKey: ['assets'],
    queryFn: async () => await NexusAssetsApi.getAssets(instance, accounts, 'products')
  })
  const handleFile = file => {
    if (previewUrl) {
      window.URL.revokeObjectURL(previewUrl)
    }
    setPreviewUrl(window.URL.createObjectURL(file))
    setFile(file)
  }

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }
    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      resetUploadToken()
    }
  }, [instance, accounts, uploadToken])

  function onClose() {
    props.refetch()
    setCategoryType('')
    setCategoryName('')
    setCategoryDescription('')
    setCategoryFamily('')
    setCategoryOrder(1)
    setCategoryListOrder(1)
    setFile(null)
    if (previewUrl) {
      window.URL.revokeObjectURL(previewUrl)
      setPreviewUrl('')
    }
    props.onClose()
  }

  function buildCategory(imageId) {
    let category = {
      name: categoryName,
      description: categoryDescription,
      categoryType: categoryType,
      imageName: imageId,
      family: categoryFamily,
      order: categoryOrder
    }
    return category
  }

  const createCrmProduct = async category => {
    await CrmProductsApi.createCrmProduct(instance, accounts, category).then(
      result => {
        enqueueSnackbar('CRM Product created.', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`CRM Product creation fail - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  const createCategory = async category => {
    await CategoriesApi.createCategory(instance, accounts, category).then(
      async result => {
        enqueueSnackbar(localizedStrings.snackbar.categoryCreated, SnackbarVariants.SUCCESS)
        if (category.categoryType === 'Product') {
          await createCrmProduct(category)
        }
      },
      error => {
        enqueueSnackbar(`${localizedStrings.snackbar.categoryCreateFailed} - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  async function uploadCategory() {
    if (file && categoryType === 'Product') {
      let formData = new FormData()
      formData.append('image', file)
      await CategoriesApi.uploadImage(instance, accounts, 'multipart/form-data', formData)
        .then(async response => {
          enqueueSnackbar(localizedStrings.snackbar.imageUploaded, SnackbarVariants.SUCCESS)
          await createCategory(buildCategory(response.data))
        })
        .catch(error => {
          enqueueSnackbar(`${localizedStrings.snackbar.imageUploadedFailed} - ${error.message}`, SnackbarVariants.ERROR)
        })
    } else {
      await createCategory(buildCategory(categoryImage))
    }
  }

  return (
    <SlideUpDialog
      id='addCategoryModel'
      maxWidth={'sm'}
      fullScreen
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>{localizedStrings.addCategory}</h3>}
      actions={
        <PrimaryButton
          id='uploadCategoryButton'
          style={{ marginRight: 15, width: 100 }}
          disabled={
            categoryType === 'Product'
              ? !(categoryFamily && categoryType && categoryName && categoryOrder)
              : categoryType === 'Product Family'
              ? !(categoryType && categoryName && categoryOrder && categoryImage)
              : !(categoryType && categoryName && categoryOrder)
          }
          onClick={async () => {
            setIsUploading(true)
            await uploadCategory()
            setIsUploading(false)
            onClose()
          }}
        >
          &nbsp;{localizedStrings.add}
        </PrimaryButton>
      }
    >
      <DialogContent>
        <CenteredDiv>
          <CustomSelect
            id='categoryTypeSelect'
            style={{ width: '100%' }}
            label={localizedStrings.category}
            required={true}
            disabled={isUploading}
            value={categoryType}
            onChange={e => {
              setCategoryType(e.target.value)
              setCategoryFamily('')
            }}
            options={CategoriesApi.categoryTypes}
          />
        </CenteredDiv>
        {categoryType === 'Product' ? (
          <CenteredDiv>
            <CustomSelect
              id='categoryFamilySelect'
              style={{ width: '100%', marginTop: 15 }}
              label={localizedStrings.productFamily}
              required={true}
              disabled={isUploading}
              value={categoryFamily}
              onChange={e => setCategoryFamily(e.target.value)}
              options={props.categoryFamlies.map(p => p.name)}
            />
          </CenteredDiv>
        ) : (
          <span></span>
        )}

        <CenteredDiv>
          <TextField
            id='categoryNameTextField'
            disabled={isUploading}
            label={localizedStrings.name}
            variant='outlined'
            style={{ marginTop: 15, width: '100%' }}
            onChange={e => setCategoryName(e.target.value)}
            required
          />
          <TextField
            id='categoryOrderTextField'
            inputProps={{ min: 1 }}
            type='number'
            disabled={isUploading}
            variant='outlined'
            style={{ marginTop: 15, marginLeft: 10, width: '20%' }}
            onChange={e => setCategoryOrder(e.target.value)}
            label={localizedStrings.order}
            required
            value={categoryOrder}
          />
          <TextField
            id='categoryListOrderTextField'
            inputProps={{ min: 1 }}
            type='number'
            disabled={isUploading}
            variant='outlined'
            style={{ marginTop: 15, marginLeft: 10, width: '20%' }}
            onChange={e => setCategoryListOrder(e.target.value)}
            label={localizedStrings.listOrder}
            required
            value={categoryListOrder}
          />
        </CenteredDiv>
        <CenteredDiv>
          <TextField
            id='categoryDescriptionTextField'
            disabled={isUploading}
            label={localizedStrings.description}
            variant='outlined'
            sx={classes.fileMetadata}
            multiline
            minRows={4}
            onChange={e => setCategoryDescription(e.target.value)}
          />
        </CenteredDiv>
        {previewUrl ? (
          <CenteredDiv>
            <div style={{ maxHeight: 300, maxWidth: 300, marginTop: 20 }}>
              <img src={previewUrl} alt='Preview' style={{ maxHeight: 300, maxWidth: 300 }} />
            </div>
          </CenteredDiv>
        ) : null}
        {categoryType === 'Product' ? (
          <CenteredDiv>
            <FileUploader
              disabled={isUploading}
              style={classes.fileMetadata}
              handleFile={handleFile}
              fileTypes='image/*'
              prompt={localizedStrings.chooseImage}
            />
          </CenteredDiv>
        ) : null}

        {categoryType === 'Product Family' ? (
          <div style={classes.fileMetadata}>
            <CenteredDiv>
              <ImageSelect
                imageStyle={{
                  width: '12em',
                  height: '12em'
                }}
                label={'Icon'}
                options={assetsData ? assetsData : []}
                value={assetsData && categoryImage && assetsData.includes(categoryImage) ? categoryImage : ''}
                onChange={value => setCategoryImage(value)}
                srcPath={`${getResourceUrl(env.runMode)}/images/products`}
                env={env}
                folder={'products'}
              />
            </CenteredDiv>
          </div>
        ) : null}
      </DialogContent>
    </SlideUpDialog>
  )
}
