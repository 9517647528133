import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class GroupsApi {
  getGroups = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/api/groups`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
  removeGroupDocOverride = async (msalInstance, accounts, id, group) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.delete(`${env.urls.gatekeeperURL}/api/groups/${group}/removedoc/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new GroupsApi()
  }
  return instance
}

export default getInstance()
