import { getResourceUrl, useWtxLocalization } from '@wavetronix/common-components'
import { React } from 'react'
import { useHistory } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import { env } from '../../index.js'
import { Box } from '@mui/material'

const classes = {
  productCardStyle: {
    height: 90,
    display: 'flex'
  },
  imageContainer: {
    maxWidth: '30%',
    minWidth: '30%',
    marginTop: 'auto'
  },
  imageStyle: {
    maxWidth: '100%',
    maxHeight: '100px',
    float: 'right'
  },
  infoBlockStyle: {
    display: 'block',
    marginLeft: 15
  },
  productName: {
    fontSize: '18px',
    marginBottom: '2px'
  },
  infoLink: {
    display: 'flex',
    fontSize: '14px',
    color: '#4B7FDE',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold'
    }
  }
}

export default function ProductTypeCard({ product, family, setCookie }) {
  const history = useHistory()
  let localizedStrings = useWtxLocalization()
  const viewProductDownloads = () => {
    history.push(`/byproduct?name=${product.name}&id=${product.id}&from=${family.current}`)
    setCookie('fromFamily', `${family.current}`, { path: '/' })
  }

  return (
    <div style={classes.productCardStyle}>
      <div style={classes.imageContainer}>
        <img
          style={classes.imageStyle}
          alt={`${product.name}`}
          src={
            product.imageName === ''
              ? `${getResourceUrl(env.runMode)}/images/products/default.png`
              : `${getResourceUrl(env.runMode)}/mydocuments/${product.imageName}`
          }
        />
      </div>
      <div style={classes.infoBlockStyle}>
        <h4 style={classes.productName}>
          {product && product.localization && product.localization[window.navigator.language]
            ? product.localization[window.navigator.language]
            : product.name}
        </h4>
        <Box sx={classes.infoLink} id={`${product.name}ViewDocsLink`} onClick={viewProductDownloads}>
          <span style={{ marginTop: 1 }}>{localizedStrings.viewProductDownloads}</span>
          <Icon>chevron_right</Icon>
        </Box>
      </div>
    </div>
  )
}
