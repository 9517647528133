import { TextField, Box } from '@mui/material'
import { CustomSelect, useWtxLocalization, CustomCheckbox } from '@wavetronix/common-components'
import { containsSubstring } from '../../utils/stringUtils'

export const DEFAULT_CRMPRODUCTS_FILTER = {
  name: '',
  productItemNumber: '',
  family: 'All',
  showIsArchived: false
}

export const filterCrmProducts = (filter, categories) =>
  categories
    ? categories.filter(
        c =>
          (filter.showIsArchived === true || (filter.showIsArchived === false && c.isArchived === false)) &&
          (containsSubstring(c.name, filter.name) || filter.name === '') &&
          (c.productItemNumbers.filter(p => containsSubstring(p, filter.productItemNumber)).length > 0 ||
            filter.productItemNumber === '') &&
          (filter.family === c.family || filter.family === 'All')
      )
    : []

export default function CrmProductsFilterDrawer({ filter, setFilter, categoryFamlies }) {
  let localizedStrings = useWtxLocalization()

  return (
    <>
      <TextField
        id='filterNameTextField'
        style={{ width: '100%' }}
        label={localizedStrings.name}
        size='small'
        value={filter.name}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
      />
      <TextField
        id='filterItemNumberTextField'
        style={{ marginTop: '10px', width: '100%' }}
        label={'Product Item Number'}
        size='small'
        value={filter.productItemNumber}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, productItemNumber: e.target.value }))}
      />
      <CustomSelect
        id='filterProductFamilySelect'
        style={{ width: '100%', marginTop: '15px' }}
        label={localizedStrings.productFamily}
        value={filter.family}
        onChange={e => setFilter(f => ({ ...f, family: e.target.value }))}
        options={['All', ...categoryFamlies.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1)).map(p => p.name)]}
      />
      <Box sx={{ display: 'flex', direction: 'row' }}>
        <CustomCheckbox
          id='filterArchivedCheckbox'
          checked={filter.showIsArchived}
          onChange={e => setFilter(f => ({ ...f, showIsArchived: e.target.checked }))}
        />
        <span style={{ paddingTop: '9px' }}>Show Archived</span>
      </Box>
    </>
  )
}
