import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material'
import { CenteredDiv, CustomAccordion } from '@wavetronix/common-components'
import { useEffect, useState, useMemo } from 'react'
import { containsSubstring } from '../../utils/stringUtils'

const isSelected = (documentProductTypes, id) => {
  if (!documentProductTypes) {
    return false
  }
  var selected = documentProductTypes
    .map(productTypeId => productTypeId === id)
    .reduce((previousValue, currentValue) => previousValue || currentValue, false)
  return selected
}

export default function CrmProductTypeSelector({ selectorStyle, documentProductTypes, productTypes, onChange, docId, title }) {
  const [selectedMap, setSelectedMap] = useState({})
  const [prevDocId, setPrevDocId] = useState(docId)
  const [prodNameFilter, setProdNameFilter] = useState('')

  useEffect(() => {
    if (
      docId !== prevDocId ||
      Object.keys(selectedMap).length !== productTypes.length ||
      productTypes.some(p => !(p.name in selectedMap))
    ) {
      let map = {}
      for (let type of productTypes) {
        map[type.name] = isSelected(documentProductTypes, type.id)
      }
      setSelectedMap(map)
      setPrevDocId(docId)
    }
  }, [productTypes, documentProductTypes, selectedMap, docId, prevDocId])

  const filteredProductTypes = useMemo(() => {
    if (productTypes) {
      if (prodNameFilter === '') return productTypes
      else return productTypes.filter(prod => containsSubstring(prod.name, prodNameFilter))
    }
  }, [productTypes, prodNameFilter])

  function handleOnClick(productType) {
    let map = {}

    for (let value in selectedMap) {
      if (value === productType.name) {
        map[value] = !selectedMap[value]
      } else {
        map[value] = selectedMap[value]
      }
    }

    setSelectedMap(map)

    let selected = productTypes
      .filter(p => map[p.name])
      .map(p => {
        let newSelected = { ...p }
        delete newSelected.isSelected
        return newSelected
      })
    onChange(selected)
  }

  if (!selectedMap) {
    return <CenteredDiv></CenteredDiv>
  }

  return (
    <div style={selectorStyle}>
      <CustomAccordion defaultExpanded={false} title={title}>
        <div style={{ margin: '15px 15px 5px 15px' }}>
          <TextField
            id={`${title}SearchTextField`}
            style={{ width: '100%' }}
            label={`Search ${title}`}
            size='small'
            value={prodNameFilter}
            variant='outlined'
            onChange={e => setProdNameFilter(e.target.value)}
          />
        </div>
        <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
          <div style={{ maxHeight: 200, width: '100%', overflow: 'auto', paddingRight: '10px', paddingLeft: '8px' }}>
            <List>
              {filteredProductTypes.map(productType => {
                return (
                  <ListItem key={productType.id} disablePadding>
                    <ListItemButton
                      id={`${productType.id}ListItemButton`}
                      role={undefined}
                      onClick={e => handleOnClick(productType)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          id={`${productType.id}Checkbox`}
                          edge='start'
                          checked={selectedMap[productType.name] ? selectedMap[productType.name] : false}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText id={productType.id} primary={productType.name} />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </div>
        </div>
      </CustomAccordion>
    </div>
  )
}
