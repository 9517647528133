import { useMsal } from '@azure/msal-react'
import { CircularProgress, Tooltip, Grid } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Card, TextField } from '@mui/material'
import {
  CenteredDiv,
  CustomSelect,
  ErrorMessage,
  PrimaryButton,
  RoleCheck,
  SnackbarVariants,
  useWtxLocalization,
  acquireAccessToken,
  parseJwt,
  PersistantFilterDiv,
  DeleteButton
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import qs from 'qs'
import { useEffect, useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CategoriesApi from '../../api/CategoriesApi'
import DocumentsApi from '../../api/DocumentsApi'
import BackButton from '../controls/BackButton'
import ProductTypeSelector from '../controls/ProductTypeSelector'
import CrmProductTypeSelector from '../controls/CrmProductTypeSelector'
import DocumentTypeSelect from '../DocumentTypeSelect'
import DocumentsOverridesModal from '../modals/DocumentsOverridesModal'
import UserFilterDrawer, { DEFAULT_USERS_FILTER } from '../drawers/UserFilterDrawer'
import { env } from '../../index.js'
import MultiDeleteModal from '../modals/MultiDeleteModal'
import FileUploader from '../controls/FileUploader'
import CrmProductsApi from '../../api/CrmProductsApi'

export default function DocumentViewerPage(props) {
  const defaultDoc = {
    id: '',
    fileName: '',
    accessLevel: '',
    description: '',
    userOverrides: [],
    documentType: '',
    products: [],
    language: [],
    crmProducts: [],
    accessories: [],
    order: 1
  }
  const { instance, accounts } = useMsal()
  let location = useLocation()
  const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [doc, setDoc] = useState(defaultDoc)
  const [overridesCopy, setOverridesCopy] = useState()
  const [documentTypes, setDocumentTypes] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [languageTypes, setLanguageTypes] = useState([])
  const [uploadToken, setUploadToken] = useState('')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [filter, setFilter] = useState(DEFAULT_USERS_FILTER)
  const [isUploading, setIsUploading] = useState(false)

  let localizedStrings = useWtxLocalization()

  let querystring = ''
  if (location.search.length > 0 && location.search[0] === '?') {
    querystring = location.search.substring(1)
  }
  let prev = qs.parse(querystring).prev
  let docId = qs.parse(querystring).id
  let productId = qs.parse(querystring).productId
  let productName = qs.parse(querystring).productName

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let categories = await CategoriesApi.getCategories(instance, accounts)
      return categories.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
  })

  const categoriesMap = useMemo(() => {
    if (categories) {
      return categories.reduce((map, obj) => {
        map[obj.id] = obj
        return map
      }, {})
    }
  }, [categories])

  const {
    data: crmProducts,
    isLoading: crmProductsLoading,
    error: crmProductsError
  } = useQuery({
    queryKey: ['crmProducts'],
    queryFn: async () => {
      let crm = await CrmProductsApi.getCrmProducts(instance, accounts)
      return crm.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
  })

  const {
    data: document,
    isLoading: documentLoading,
    refetch: docRefetch,
    error: documentError
  } = useQuery({ queryKey: ['document'], queryFn: async () => await DocumentsApi.getDocument(instance, accounts, docId) })

  useEffect(() => {
    if (categories) {
      let documentTypes = categories.filter(c => c.categoryType === 'Document Type')
      let productTypes = categories.filter(c => c.categoryType === 'Product')
      let languageTypes = categories.filter(c => c.categoryType === 'Language')

      setDocumentTypes(documentTypes)
      setProductTypes(productTypes)
      setLanguageTypes(languageTypes)
    }
  }, [categories])

  useEffect(() => {
    if (document) {
      setDoc(document)
      if (!document.userOverrides) document.userOverrides = []
      setOverridesCopy(document.userOverrides)
    }
  }, [document])

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }

    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      resetUploadToken()
    }
  }, [accounts, instance, uploadToken])

  function setDocProducts(productTypes) {
    setDoc({ ...doc, products: productTypes })
  }

  function setDocCRMProducts(productTypes) {
    setDoc({ ...doc, crmProducts: productTypes })
  }

  function setDocLanguages(languageTypes) {
    setDoc({ ...doc, language: languageTypes })
  }

  async function uploadWithFormData(file) {
    setIsUploading(true)
    if (file) {
      const formData = new FormData()
      formData.append('document', file)
      let fileKey = enqueueSnackbar(`Uploading file to blob storage...`, SnackbarVariants.LOADING)
      await DocumentsApi.updateDocument(instance, accounts, 'multipart/form-data', formData, doc.id)
        .then(response => {
          closeSnackbar(fileKey)
          enqueueSnackbar(localizedStrings.snackbar.docUploaded, SnackbarVariants.SUCCESS)
        })
        .catch(error => {
          closeSnackbar(fileKey)
          enqueueSnackbar(error.response.data, SnackbarVariants.ERROR)
        })
    }

    setIsUploading(false)
  }

  if (documentLoading || categoriesLoading || !document || crmProductsLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  if (documentError || categoriesError || crmProductsError) {
    if (documentError) {
      return (
        <CenteredDiv>
          <ErrorMessage error={documentError} />
        </CenteredDiv>
      )
    } else if (categoriesError) {
      return (
        <CenteredDiv>
          <ErrorMessage error={categoriesError} />
        </CenteredDiv>
      )
    } else if (crmProductsError) {
      return (
        <CenteredDiv>
          <ErrorMessage error={crmProductsError} />
        </CenteredDiv>
      )
    }
  }

  return (
    <RoleCheck env={env} allowedRoles={['Documents Admin', 'Gatekeeper Admin']}>
      <MultiDeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        deleteDocuments={[doc]}
        refetchDocuments={async () => {
          history.push('..')
        }}
      />
      <PersistantFilterDiv
        page={
          <div>
            <BackButton
              previous={prev === 'byproduct' ? `to ${productName}` : `to Search`}
              path={prev === 'byproduct' ? `/byproduct?name=${productName}&id=${productId}` : `../${prev}`}
              onClick={() => {}}
            />
            <div style={{ display: 'flex' }}>
              {doc.accessLevel === 'Basic' ? (
                <div style={{ display: 'flex' }}>
                  <h2 style={{ marginLeft: 15, marginTop: 0 }}>{doc.fileName}</h2>

                  <CopyToClipboard
                    id={`${doc.id}CopyToClipboard`}
                    text={`${env.urls.documentsURL}/documents/file/public/${encodeURIComponent(doc.id)}`}
                    onCopy={() => enqueueSnackbar(localizedStrings.snackbar.publicURLCopied, SnackbarVariants.SUCCESS)}
                  >
                    <Tooltip title='Public Access URL'>
                      <ContentCopyIcon style={{ marginBottom: 'auto', marginTop: 5, marginLeft: '15px', cursor: 'pointer' }} />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              ) : (
                <h2 style={{ marginLeft: 15, marginTop: 0 }}>{doc.fileName}</h2>
              )}

              <FileUploader
                disabled={isUploading}
                handleFile={async file => {
                  await uploadWithFormData(file)
                }}
                fileTypes='*'
                prompt={localizedStrings.chooseFile}
                useIcon={true}
                iconStyle={{ marginLeft: '10px' }}
                iconToolTip={localizedStrings.updateDocument}
              />
            </div>
            <Grid container>
              <Grid item xl={4} lg={5} md={5} sm={12} xs={12}>
                <Card style={{ margin: '0px 15px 15px 15px', padding: 15 }}>
                  <CenteredDiv>
                    <CustomSelect
                      id='docAccessLevelSelect'
                      required
                      style={{ width: '100%', marginTop: 15 }}
                      label={localizedStrings.accessLevel}
                      value={doc.accessLevel}
                      onChange={e => setDoc(d => ({ ...d, accessLevel: e.target.value }))}
                      options={['Basic', 'Intermediate', 'Advanced']}
                    />
                    <TextField
                      id='docOrderTextField'
                      inputProps={{ min: 1 }}
                      type='number'
                      variant='outlined'
                      style={{ width: '18%', marginLeft: 15, marginTop: 15 }}
                      onChange={e => setDoc(d => ({ ...d, order: e.target.value }))}
                      label={localizedStrings.order}
                      required
                      value={doc.order}
                      size='small'
                    />
                  </CenteredDiv>
                  <CenteredDiv>
                    <ProductTypeSelector
                      title={localizedStrings.products}
                      selectorStyle={{ display: 'flex', width: '100%', marginTop: 15 }}
                      documentProductTypes={categoriesMap ? doc.products.map(id => categoriesMap[id]) : []}
                      productTypes={productTypes}
                      onChange={selectedProductTypes => {
                        setDocProducts(selectedProductTypes.map(prod => prod.id))
                      }}
                      docId={doc.id}
                    />
                  </CenteredDiv>
                  <CenteredDiv>
                    <CrmProductTypeSelector
                      title={'CRM Products'}
                      selectorStyle={{ display: 'flex', width: '100%', marginTop: 15 }}
                      documentProductTypes={doc.crmProducts}
                      productTypes={crmProducts}
                      onChange={crmTypes => {
                        return setDocCRMProducts([...crmTypes.map(t => t.id)])
                      }}
                      docId={doc.id}
                    />
                  </CenteredDiv>
                  <CenteredDiv>
                    <DocumentTypeSelect
                      style={{ width: '100%', marginTop: 15 }}
                      required
                      currentValue={categoriesMap && categoriesMap[doc.documentType] ? categoriesMap[doc.documentType] : null}
                      documentTypes={documentTypes}
                      onChange={item => {
                        setDoc({ ...doc, documentType: item.id })
                      }}
                    />
                  </CenteredDiv>
                  <CenteredDiv>
                    <ProductTypeSelector
                      title={localizedStrings.languages}
                      selectorStyle={{ display: 'flex', width: '100%', marginTop: 15 }}
                      documentProductTypes={categoriesMap ? doc.language.map(id => categoriesMap[id]) : []}
                      productTypes={languageTypes}
                      onChange={selectedLanguages => {
                        console.log(selectedLanguages)
                        setDocLanguages(selectedLanguages.map(lang => lang.id))
                      }}
                      docId={doc.id}
                    />
                  </CenteredDiv>
                  <TextField
                    id='docDescriptionTextField'
                    style={{ marginTop: 15, width: '100%' }}
                    label={localizedStrings.description}
                    variant='outlined'
                    multiline
                    rows={4}
                    value={doc.description}
                    onChange={e => setDoc(d => ({ ...d, description: e.target.value }))}
                    required
                  />
                  <div style={{ marginTop: 15 }}>
                    <DeleteButton id='deleteModalOpenButton' onClick={() => setDeleteModalOpen(true)}>
                      {localizedStrings.deleteDocument}
                    </DeleteButton>
                    <PrimaryButton
                      id='docChangesSaveButton'
                      style={{ float: 'right', minWidth: '150px' }}
                      disabled={
                        doc.products.length < 1 ||
                        doc.language.length < 1 ||
                        doc.description === '' ||
                        doc.description === undefined ||
                        doc.description === null
                      }
                      onClick={async e => {
                        setButtonsDisabled(true)
                        let key = enqueueSnackbar(localizedStrings.snackbar.docInfoUpdate, {
                          variant: 'default',
                          anchorOrigin: { vertical: 'top', horizontal: 'center' },
                          action: <CircularProgress />,
                          persist: true
                        })
                        const newDoc = { ...doc, userOverrides: overridesCopy, crmProducts: doc.crmProducts }
                        await DocumentsApi.updateInfo(instance, accounts, newDoc)
                          .catch(async () => {
                            enqueueSnackbar(localizedStrings.snackbar.docInfoUpdateFailed, SnackbarVariants.ERROR)
                          })
                          .then(async () => {
                            enqueueSnackbar(localizedStrings.snackbar.docInfoUpdated, SnackbarVariants.SUCCESS)
                          })
                          .finally(() => {
                            docRefetch()
                            setButtonsDisabled(false)
                          })
                        closeSnackbar(key)
                      }}
                    >
                      {localizedStrings.save}
                    </PrimaryButton>
                  </div>
                </Card>
              </Grid>
              <Grid item xl={8} lg={7} md={7} sm={12} xs={12}>
                <DocumentsOverridesModal
                  document={doc}
                  setButtonsDisabled={e => setButtonsDisabled(e)}
                  buttonsDisabled={buttonsDisabled}
                  userOverrides={overridesCopy}
                  filter={filter}
                  docRefetch={docRefetch}
                />
              </Grid>
            </Grid>
          </div>
        }
        drawer={<UserFilterDrawer filter={filter} setFilter={setFilter} />}
        resetFilter={() => {
          setFilter(DEFAULT_USERS_FILTER)
        }}
      />
    </RoleCheck>
  )
}
