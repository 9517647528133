import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class CategoriesApi {
  categoryTypes = ['Product', 'Document Type', 'Language', 'Product Family']

  getCategories = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.documentsURL}/categories`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Categories Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Category may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  getCategory = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.documentsURL}/categories/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Category Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Category may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  createCategory = async (msalInstance, accounts, category) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.documentsURL}/categories`, category, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Create Category Failed!')
        console.log(`Sent:\n${JSON.stringify(category)}`)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  updateCategory = async (msalInstance, accounts, category) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .put(`${env.urls.documentsURL}/categories`, category, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Update Category Failed!')
        console.log(`Sent:\n${JSON.stringify(category)}`)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }
  //https://axios-http.com/docs/handling_errors
  deleteCategory = async (msalInstance, accounts, categoryId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.documentsURL}/categories/${categoryId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => true)
      .catch(error => {
        console.log('Delete Category Failed!')
        if (error.response) {
          console.log(`Sent:\n${categoryId}`)
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 409) {
            throw new Error('A document requires the category')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  uploadImage = async (msal, accounts, contentType, image) => {
    let token = await acquireAccessToken(msal, accounts, env)
    return await axios({
      url: `${env.urls.documentsURL}/categories/image`,
      method: 'POST',
      data: image,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`
      }
    })
  }

  getLanguages = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.documentsURL}/categories/languages`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Languages Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Languages may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new CategoriesApi()
  }
  return instance
}

export default getInstance()
