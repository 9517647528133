import { IconButton, Popper, Grow, Paper, ClickAwayListener, CircularProgress, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { NexusAssetList } from './NexusAssetList'
import EditIcon from '@mui/icons-material/Edit'
// This can be used to render a customized select without custom individual styling for
// its options. If you need to customize the options individually, it is best to use the
// Material UI FormControl and Select components directly.

export default function ImageSelect({
  style,
  required,
  onChange,
  value,
  srcPath,
  label,
  options,
  disabled,
  env,
  folder,
  imageStyle
}) {
  const [open, setOpen] = useState(false)
  const prevOpen = React.useRef(open)
  const anchorRef = React.useRef(null)
  const [mouseOver, setMouseOver] = useState(false)

  React.useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
    setMouseOver(true)
  }

  const handleClose = () => {
    if (mouseOver === false) {
      setOpen(false)
    }
  }

  return (
    <>
      <div>
        {value === '' ? <CircularProgress /> : <img alt={value} style={imageStyle} src={`${srcPath}/${value}`} />}
        <Tooltip title='Edit Icon'>
          <IconButton
            ref={anchorRef}
            id='composition-button'
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            id='imageSelectorPopper'
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            sx={{ zIndex: 100, width: '500px', height: '500px' }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement='bottom-start'
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                }}
              >
                <Paper elevation={3}>
                  <div>
                    <NexusAssetList
                      assetsData={options}
                      environment={env}
                      folder={folder}
                      onClick={value => onChange(value)}
                      height={'400px'}
                      width={'80px'}
                      cols={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
                    />
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      </div>
    </>
  )
}
