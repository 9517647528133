import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import UserFilterDrawer, { DEFAULT_USERS_FILTER, filterUsers } from '../drawers/UserFilterDrawer'
import { useQuery } from '@tanstack/react-query'
import UsersApi from '../../api/UsersApi'
import {
  CenteredDiv,
  CustomDataGrid,
  ErrorMessage,
  RoleCheck,
  useWtxLocalization,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import UserModal from '../modals/UserModal'
import { CircularProgress } from '@mui/material'
import PageNavbar from '../PageNavbar'
import { URL_MAP } from '../MainPage'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { env } from '../../index.js'

const DEFAULT_USER = {
  givenName: '',
  surname: '',
  documentsAccessLevel: {
    wavetronix: 'Basic',
    islandradar: 'Basic',
    fathomradar: 'Basic'
  }
}

export default function UsersAccessPage(props) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const { instance, accounts } = useMsal()
  const [filter, setFilter] = useState(DEFAULT_USERS_FILTER)
  const [userModalState, setUserModalState] = useState({
    open: false,
    user: DEFAULT_USER
  })
  let localizedStrings = useWtxLocalization()

  const {
    data: users,
    refetch: usersRefetch,
    error: usersError,
    isLoading
  } = useQuery({ queryKey: ['users'], queryFn: async () => await UsersApi.getUsers(instance, accounts) })

  const columns = [
    {
      headerName: localizedStrings.firstName,
      field: 'givenName',
      flex: 2,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.lastName,
      field: 'surname',
      flex: 2,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.email,
      field: 'email',
      flex: 2,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.accessLevel,
      field: 'documentsAccessLevel',
      flex: 2,
      valueGetter: u => u.row.documentsAccessLevel[env.basicAuthentication.fromCompany],
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    }
  ]

  useEffect(() => {
    setUserModalState(s => (s.user && s.user.id && users ? { ...s, user: users.find(u => u.id === s.user.id) } : s))
  }, [users, filter])

  if (usersError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={usersError} />
      </CenteredDiv>
    )
  }

  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  return (
    <>
      <RoleCheck env={env} allowedRoles={['Documents Admin', 'Gatekeeper Admin']}>
        <div style={{ display: 'flex', width: '100%' }}>
          <UserModal
            open={userModalState.open}
            onClose={() => setUserModalState({ open: false, user: DEFAULT_USER })}
            user={userModalState.user}
            styles={props.styles}
            refetch={usersRefetch}
          />
          <PageNavbar
            value={props.tabState ? URL_MAP[props.tabState.current] : 1}
            onChangeCallback={id => props.changeTabState(s => ({ ...s, current: id }))}
          />
          <div style={{ display: 'flex', width: '100%' }}>
            <PersistantFilterDiv
              defaultOpen={largeScreenFeaturesActive}
              page={
                <div
                  style={{
                    marginLeft: '24px'
                  }}>
                  <CustomDataGrid
                    columns={columns}
                    rows={users ? filterUsers(filter, users) : []}
                    loading={isLoading}
                    onRowClick={e => setUserModalState({ open: true, user: e.row })}
                    cursor='pointer'
                  />
                </div>
              }
              drawer={<UserFilterDrawer filter={filter} setFilter={setFilter} />}
              resetFilter={() => setFilter(DEFAULT_USERS_FILTER)}
            />
          </div>
        </div>
      </RoleCheck>
    </>
  )
}
