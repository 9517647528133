import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  CenteredDiv,
  PrimaryButton,
  CustomSelect,
  parseJwt,
  SnackbarVariants,
  getResourceUrl,
  useWtxLocalization,
  acquireAccessToken,
  SlideUpDialog,
  DeleteButton,
  CustomAccordion,
  CustomCheckbox
} from '@wavetronix/common-components'
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { env } from '../../index.js'
import { TextField, DialogContent, Grid, Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import CategoriesApi from '../../api/CategoriesApi'
import FileUploader from '../controls/FileUploader'
import ImageSelect from '../NexusAssets/ImageSelect'
import NexusAssetsApi from '../../api/NexusAssetsApi'

const classes = {
  paper: {
    position: 'absolute',
    width: '50%',
    height: '50%',
    backgroundColor: 'white',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '16px 32px 24px'
  },
  outside: {
    backgroundColor: 'white'
  },
  fileMetadata: {
    marginTop: '15px',
    width: '100%'
  },
  accordionList: {
    borderRadius: '5px',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgb(196, 196, 196)',
    '&:before': {
      display: 'none'
    }
  },
  accordionSummary: {
    width: '100%',
    borderRadius: '5px',
    minHeight: '40px'
  },
  accordionDetails: {
    padding: '10px',
    margin: '0px'
  }
}

export default function EditCategoryModal(props) {
  const { instance, accounts } = useMsal()
  const [categoryId, setCategoryId] = useState('')
  const [categoryType, setCategoryType] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [categoryDescription, setCategoryDescription] = useState('')
  const [categoryImage, setCategoryImage] = useState('')
  const [categoryFamily, setCategoryFamily] = useState('')
  const [categoryIsArchived, setCategoryIsArchived] = useState(false)
  const [categoryOrder, setCategoryOrder] = useState(1)
  const [categoryListOrder, setCategoryListOrder] = useState(1)
  const [categoryCompany, setCategoryCompany] = useState('wavetronix')
  const [categoryLanguageOptions, setCategoryLanguageOptions] = useState({})
  const [uploadToken, setUploadToken] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState(null)
  const [previewUrl, setPreviewUrl] = useState('')

  let localizedStrings = useWtxLocalization()

  const { data, refetch } = useQuery({
    queryKey: ['languages'],
    queryFn: async () => await CategoriesApi.getLanguages(instance, accounts)
  })
  const { data: assetsData } = useQuery({
    queryKey: ['assets'],
    queryFn: async () => await NexusAssetsApi.getAssets(instance, accounts, 'products')
  })

  const handleFile = file => {
    if (previewUrl) {
      window.URL.revokeObjectURL(previewUrl)
    }
    setPreviewUrl(window.URL.createObjectURL(file))
    setFile(file)
  }

  useEffect(() => {
    if (data) {
      setCategoryLanguageOptions(
        data.reduce((map, value) => {
          if (props.current && props.current.localization && props.current.localization[value]) {
            map[value] = props.current.localization[value]
          } else {
            map[value] = ''
          }

          return map
        }, {})
      )
    }
  }, [data, props])

  useEffect(() => {
    if (props.current) {
      setCategoryId(props.current.id)
      setCategoryType(props.current.categoryType)
      setCategoryName(props.current.name)
      setCategoryDescription(props.current.description)
      setCategoryImage(props.current.imageName)
      setCategoryFamily(props.current.family)
      setCategoryOrder(props.current.order)
      setCategoryListOrder(props.current.listOrder)
      setCategoryIsArchived(props.current.isArchived)
      setCategoryCompany(props.current.fromCompany)
    }
  }, [props])

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }
    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      resetUploadToken()
    }
  }, [instance, accounts, uploadToken])

  function onClose() {
    props.refetch()
    refetch()
    props.onClose()
    setFile(null)
    if (previewUrl) {
      window.URL.revokeObjectURL(previewUrl)
      setPreviewUrl('')
    }
  }

  function createCategory(imageId) {
    const category = {
      id: categoryId,
      name: categoryName,
      description: categoryDescription,
      categoryType: categoryType,
      imageName: imageId ? imageId : categoryImage,
      family: categoryFamily,
      order: categoryOrder,
      listOrder: categoryListOrder,
      localization: categoryLanguageOptions,
      isArchived: categoryIsArchived,
      fromCompany: categoryCompany
    }
    return category
  }

  const deleteCategory = async id => {
    await CategoriesApi.deleteCategory(instance, accounts, id).then(
      result => {
        enqueueSnackbar(localizedStrings.snackbar.categoryDeleted, SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`${localizedStrings.snackbaorcategoryDeletedFailed} - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  const updateCategory = async category => {
    await CategoriesApi.updateCategory(instance, accounts, category).then(
      _ => {
        enqueueSnackbar(localizedStrings.updateCategory, SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`${localizedStrings.snackbar.categoryUpdatedFailed} - ${error.message}`, SnackbarVariants.ERROR)
      }
    )
  }

  async function updateCategoryAndImage() {
    if (file) {
      let formData = new FormData()
      formData.append('image', file)
      await CategoriesApi.uploadImage(instance, accounts, 'multipart/form-data', formData)
        .then(async res => {
          enqueueSnackbar(localizedStrings.snackbar.imageUploaded, SnackbarVariants.SUCCESS)
          await updateCategory(createCategory(res.data))
        })
        .catch(error => {
          enqueueSnackbar(`${localizedStrings.snackbar.imageUploadedFailed} - ${error.message}`, SnackbarVariants.ERROR)
        })
    } else {
      await updateCategory(createCategory())
    }
  }

  return (
    <SlideUpDialog
      id='editCategoryModal'
      maxWidth={'lg'}
      fullScreen
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>{localizedStrings.updateCategory}</h3>}
      actions={
        <>
          <DeleteButton
            id='deleteCategoryButton'
            style={{ marginLeft: 15 }}
            onClick={async e => {
              setIsUploading(true)
              await deleteCategory(categoryId)
              setIsUploading(false)
              onClose()
            }}
          >
            Delete Category
          </DeleteButton>
          <div style={{ flex: '1 0 0' }} />
          <PrimaryButton
            id='updateCategoryButton'
            style={{ marginRight: 15 }}
            disabled={
              categoryType === 'Product'
                ? !(categoryFamily && categoryType && categoryName && categoryOrder)
                : !(categoryType && categoryName && categoryOrder)
            }
            onClick={async () => {
              setIsUploading(true)
              await updateCategoryAndImage()
              setIsUploading(false)
              onClose()
            }}
          >
            &nbsp;Update
          </PrimaryButton>
        </>
      }
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <CenteredDiv>
              <CustomSelect
                id='categoryTypeSelect'
                style={{ width: '100%' }}
                label={localizedStrings.category}
                required={true}
                disabled={true}
                value={categoryType}
                onChange={e => setCategoryType(e.target.value)}
                options={props.categories}
              />
            </CenteredDiv>
            <CenteredDiv>
              <CustomSelect
                id='categoryCompanySelect'
                style={{ width: '100%', marginTop: 15 }}
                label={'Company'}
                required={true}
                value={categoryCompany}
                onChange={e => setCategoryCompany(e.target.value)}
                options={['wavetronix', 'islandradar', 'fathom']}
              />
            </CenteredDiv>
            {categoryType === 'Product' ? (
              <CenteredDiv>
                <CustomSelect
                  id='categoryFamilySelect'
                  style={{ width: '100%', marginTop: 15 }}
                  label={localizedStrings.productFamily}
                  required={true}
                  disabled={isUploading}
                  value={categoryFamily}
                  onChange={e => setCategoryFamily(e.target.value)}
                  options={props.categoryFamlies.map(p => p.name)}
                />
              </CenteredDiv>
            ) : (
              <span></span>
            )}
            <CenteredDiv>
              <TextField
                id='categoryNameTextField'
                disabled={isUploading || categoryType === 'Language'}
                label={localizedStrings.name}
                variant='outlined'
                style={{ marginTop: '15px', width: '100%' }}
                onChange={e => setCategoryName(e.target.value)}
                required
                value={categoryName}
              />
              <TextField
                id='categoryOrderTextField'
                inputProps={{ min: 1 }}
                type='number'
                disabled={isUploading}
                variant='outlined'
                style={{ marginTop: '15px', marginLeft: '10px', width: '20%' }}
                onChange={e => setCategoryOrder(e.target.value)}
                label={localizedStrings.order}
                required
                value={categoryOrder}
              />
              <TextField
                id='categoryListOrderTextField'
                inputProps={{ min: 1 }}
                type='number'
                disabled={isUploading}
                variant='outlined'
                style={{ marginTop: '15px', marginLeft: '10px', width: '20%' }}
                onChange={e => setCategoryListOrder(e.target.value)}
                label={localizedStrings.listOrder}
                required
                value={categoryListOrder}
              />
            </CenteredDiv>

            <CenteredDiv>
              <TextField
                id='categoryDescriptionTextField'
                disabled={isUploading}
                label={localizedStrings.description}
                value={categoryDescription}
                variant='outlined'
                sx={classes.fileMetadata}
                multiline
                minRows={4}
                onChange={e => setCategoryDescription(e.target.value)}
              />
            </CenteredDiv>

            {categoryType === 'Product' || categoryType === 'Product Family' ? (
              <Box sx={{ display: 'flex', direction: 'row' }}>
                <CustomCheckbox
                  id='categoryArchivedCheckbox'
                  checked={categoryIsArchived}
                  onChange={event => setCategoryIsArchived(event.target.checked)}
                />
                <span style={{ paddingTop: '9px' }}>Archive Category</span>
              </Box>
            ) : null}

            {categoryType === 'Product' ? (
              <>
                <CenteredDiv>
                  <FileUploader
                    disabled={isUploading}
                    style={classes.fileMetadata}
                    handleFile={handleFile}
                    fileTypes='image/*'
                    prompt={localizedStrings.chooseImage}
                  />
                </CenteredDiv>
                <CenteredDiv>
                  <div style={{ maxHeight: 300, maxWidth: 300, marginTop: 20 }}>
                    <img
                      src={
                        previewUrl
                          ? previewUrl
                          : categoryImage === ''
                          ? `${getResourceUrl(env.runMode)}/images/products/default.png`
                          : `${getResourceUrl(env.runMode)}/mydocuments/${categoryImage}`
                      }
                      alt='Preview'
                      style={{ maxWidth: 300, maxHeight: 300 }}
                    />
                  </div>
                </CenteredDiv>
              </>
            ) : null}

            {categoryType === 'Product Family' ? (
              <CenteredDiv>
                <ImageSelect
                  imageStyle={{
                    width: '12em',
                    height: '12em'
                  }}
                  label={'Icon'}
                  options={assetsData ? assetsData : []}
                  value={assetsData && categoryImage && assetsData.includes(categoryImage) ? categoryImage : ''}
                  onChange={value => setCategoryImage(value)}
                  srcPath={`${getResourceUrl(env.runMode)}/images/products`}
                  env={env}
                  folder={'products'}
                />
              </CenteredDiv>
            ) : null}
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div>
              <CenteredDiv>
                <CustomAccordion defaultExpanded disableGutters title={'Localization'} detailStyle={{ margin: '10px' }}>
                  {Object.entries(categoryLanguageOptions).map((values, i) => {
                    return (
                      <Grid container spacing={2} key={i}>
                        <Grid item sm={2} xs={12}>
                          <h4 style={{ float: 'right', paddingTop: '25px', margin: '0px' }}>{values[0]}</h4>
                        </Grid>
                        <Grid item sm={10} xs={12}>
                          <TextField
                            id={`${values[0]}LanguageTextField`}
                            value={values[1]}
                            size='small'
                            label='Name Translation'
                            sx={{ marginRight: '10px', marginTop: '15px', width: '100%' }}
                            onChange={e => setCategoryLanguageOptions(options => ({ ...options, [values[0]]: e.target.value }))}
                          />
                        </Grid>
                      </Grid>
                    )
                  })}
                </CustomAccordion>
              </CenteredDiv>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </SlideUpDialog>
  )
}
