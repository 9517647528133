import React, { useState } from 'react'
import {
  RegularButton,
  getResourceUrl,
  CenteredDiv,
  WtxColors,
  DeleteButton,
  SnackbarVariants,
  ErrorMessage
} from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { IconButton, CircularProgress, Grid } from '@mui/material'
import NexusAssetsApi from '../../api/NexusAssetsApi'
import { AddOutlined } from '@mui/icons-material'
import SquareIcon from '@mui/icons-material/Square'
import AddAssetModal from './AddAssetModal'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import { useSnackbar } from 'notistack'
import { NexusAssetList } from './NexusAssetList'
import { env } from '../../index.js'
import PageNavbar from '../PageNavbar'
import { URL_MAP } from '../MainPage'

export default function NexusAssetsPage(props) {
  const { instance, accounts } = useMsal()
  const [previewImage, setPreviewImage] = useState('')
  const [previewBackground, setPreviewBackground] = useState('white')
  const [openAssetModal, setOpenAssetModal] = useState(false)
  const [openDeleteAssetModal, setOpenDeleteAssetModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: assetsData,
    isLoading: assetsLoading,
    refetch: assetsRefetch,
    error: assetsError
  } = useQuery({ queryKey: ['assets'], queryFn: async () => await NexusAssetsApi.getAssets(instance, accounts, 'products') })

  const deleteAsset = async () => {
    if (previewImage) {
      await NexusAssetsApi.deleteAsset(instance, accounts, 'products', previewImage)
        .then(async response => {
          enqueueSnackbar('Asset delete succeeded', SnackbarVariants.SUCCESS)
        })
        .catch(error => {
          enqueueSnackbar(`Asset delete - ${error.message}`, SnackbarVariants.ERROR)
        })
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <PageNavbar
        value={props.tabState ? URL_MAP[props.tabState.current] : 2}
        onChangeCallback={id => props.changeTabState(s => ({ ...s, current: id }))}
      />
      <>
        <AddAssetModal open={openAssetModal} onClose={() => setOpenAssetModal(false)} assetsRefetch={assetsRefetch} />
        <DeleteConfirmationModal
          message={
            <>
              <p>Verify that you want to delete this asset:</p>
              <p>{previewImage}</p>
              <p style={{ color: WtxColors.INNOVATION_RED }}>
                Important: Any products in My Documents and My Software using this asset will show preview text instead of the
                image.
              </p>
            </>
          }
          open={openDeleteAssetModal}
          onClose={() => setOpenDeleteAssetModal(false)}
          onDelete={async () => {
            await deleteAsset()
            assetsRefetch()
            setOpenDeleteAssetModal(false)
            setPreviewImage('')
          }}
        />
        <div style={{ margin: '10px', width: '100%' }}>
          <RegularButton
            id='openAssetModalButton'
            startIcon={<AddOutlined />}
            style={{ float: 'right', marginRight: '24px' }}
            onClick={() => {
              setOpenAssetModal(true)
            }}
          >
            Add Asset
          </RegularButton>

          <div>
            {assetsError ? (
              <div style={{ margin: '10px', width: '100%' }}>
                <CenteredDiv>
                  <ErrorMessage error={assetsError} />
                </CenteredDiv>
              </div>
            ) : null}
            {assetsLoading ? (
              <div style={{ margin: '10px', width: '100%' }}>
                <CenteredDiv>
                  <CircularProgress />
                </CenteredDiv>
              </div>
            ) : null}
            {!assetsError && !assetsLoading ? (
              assetsData && assetsData.length > 0 ? (
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <NexusAssetList
                      assetsData={assetsData}
                      assetsLoading={assetsLoading}
                      onClick={value => {
                        setPreviewImage(value)
                      }}
                      cols={{ xs: 1, sm: 1, md: 3, lg: 4, xl: 5 }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <CenteredDiv>
                      <h4>Asset Preview</h4>
                    </CenteredDiv>

                    <CenteredDiv>
                      {previewImage && previewImage !== '' ? (
                        <div style={{ display: 'block' }}>
                          <CenteredDiv>
                            <img
                              alt={previewImage}
                              style={{
                                maxWidth: '12em',
                                maxHeight: '12em',
                                backgroundColor: previewBackground
                              }}
                              src={`${getResourceUrl(env.runMode)}/images/products/${previewImage}`}
                            />
                          </CenteredDiv>
                          <CenteredDiv>
                            <div>{previewImage}</div>
                          </CenteredDiv>
                          <div style={{ marginTop: '15px' }}>
                            <CenteredDiv>
                              <IconButton id='previewBackgroundWhiteButton' onClick={() => setPreviewBackground('white')}>
                                <SquareIcon sx={{ color: 'white', border: '1px solid black' }} />
                              </IconButton>
                              <IconButton id='previewBackgroundBlackButton' onClick={() => setPreviewBackground('black')}>
                                <SquareIcon sx={{ color: 'black', border: '1px solid black' }} />
                              </IconButton>
                            </CenteredDiv>
                          </div>

                          <CenteredDiv>Can't see the image? Try changing the background color.</CenteredDiv>
                          <CenteredDiv>
                            <DeleteButton
                              id='openAssetDeleteConfirmationButton'
                              style={{ margin: '15px', width: '100%' }}
                              onClick={() => setOpenDeleteAssetModal(true)}
                            >
                              Delete Asset
                            </DeleteButton>
                          </CenteredDiv>
                        </div>
                      ) : (
                        'Select image to preview'
                      )}
                    </CenteredDiv>
                  </Grid>
                </Grid>
              ) : (
                <h1
                  style={{
                    margin: 'auto',
                    textAlign: 'center',
                    padding: 15,
                    marginTop: 15
                  }}
                >
                  No assets.
                </h1>
              )
            ) : null}
          </div>
        </div>
      </>
    </div>
  )
}
