import { PrimaryButton } from '@wavetronix/common-components'
import React from 'react'
import { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'

export default function FileUploader({ iconStyle, iconToolTip, useIcon = false, ...props }) {
  const [fileName, setFileName] = useState(props.prompt)
  const hiddenFileInput = React.useRef(null)

  const handleClick = async event => {
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      setFileName(fileUploaded.name)
    } else {
      setFileName(props.prompt)
    }

    props.handleFile(fileUploaded)
  }

  return (
    <div style={props.style}>
      {useIcon === true ? (
        <Tooltip title={iconToolTip}>
          <IconButton id='fileUploadButton' onClick={handleClick} sx={iconStyle} disabled={props.disabled}>
            <FileUploadOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <PrimaryButton
          id='fileUploadButton'
          style={{ width: '100%', marginBottom: 15 }}
          onClick={handleClick}
          disabled={props.disabled}
        >
          {fileName}
        </PrimaryButton>
      )}

      <input
        type='file'
        id='file'
        ref={hiddenFileInput}
        accept={props.fileTypes}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  )
}
