import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { CenteredDiv, useWtxLocalization } from '@wavetronix/common-components'
import React from 'react'
import { useState, useEffect } from 'react'

// This can be used to render a customized select without custom individual styling for
// its options. If you need to customize the options individually, it is best to use the
// Material UI FormControl and Select components directly.
export default function DocumentTypeSelect({ style, required, onChange, currentValue, documentTypes, disabled }) {
  const [currentDocumentType, setCurrentDocumentType] = useState()
  let localizedStrings = useWtxLocalization()

  function isEveryThingOk() {
    if (documentTypes && documentTypes.constructor === Array) {
      let itemList = documentTypes.map(t => t === currentDocumentType)
      let result = itemList.reduce((previousValue, currentValue) => previousValue || currentValue, false)
      return result
    }
    return false
  }

  useEffect(() => {
    // Gets the current doc type from the list of doc types
    // If the current do type or its id are null
    // returns the first item in the documentTypes list
    const getDefaultDocType = currentValue => {
      if (currentValue && currentValue.id) {
        let docTypes = documentTypes.filter(docType => docType.id === currentValue.id)
        if (docTypes.length > 0) {
          return docTypes[0]
        }
      }
      return documentTypes[0]
    }

    if (documentTypes && documentTypes.length > 0) {
      let dt = getDefaultDocType(currentValue)
      if (dt) {
        setCurrentDocumentType(dt)
      }
    }
  }, [documentTypes, currentValue])

  const itemFormatter = docType => `${docType.name}`

  function handleChange(docType) {
    setCurrentDocumentType(docType)
    onChange(docType)
  }

  if (!isEveryThingOk()) {
    return <CenteredDiv>Getting document info...</CenteredDiv>
  }

  if (currentDocumentType && documentTypes && documentTypes.length > 0) {
    return (
      <FormControl style={style} variant='outlined' size='small' required={required}>
        <InputLabel id='selectLabel'>{localizedStrings.documentType}</InputLabel>
        <Select
          id='docTypeSelect'
          labelId='selectLabel'
          value={currentDocumentType}
          defaultValue={currentDocumentType}
          onChange={e => handleChange(e.target.value)}
          label={'Document Type'}
          disabled={disabled}
          renderValue={itemFormatter}
        >
          {documentTypes.map((docType, index) => (
            <MenuItem id={`${docType}MenuItem`} value={docType} key={index}>
              {itemFormatter(docType)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  } else {
    return <div></div>
  }
}
