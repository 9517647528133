import { DialogContent } from '@mui/material'
import { useWtxLocalization, DeleteButton, CustomDataGrid, SnackbarVariants, SlideUpDialog } from '@wavetronix/common-components'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import DocumentsApi from '../../api/DocumentsApi'
import GroupsApi from '../../api/GroupsApi'
import { useSnackbar } from 'notistack'

export default function MultiDeleteModal({ open, onClose, deleteDocuments, refetchDocuments }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const localizedStrings = useWtxLocalization()
  const [disableButtons, setDisableButtons] = useState(false)
  const [pageSize, setPageSize] = useState(10)

  const { data: groupsData } = useQuery({
    queryKey: ['groupsAllQuery'],
    queryFn: async () => await GroupsApi.getGroups(instance, accounts)
  })

  const columns = [
    {
      headerName: localizedStrings.name,
      field: 'fileName',
      flex: 2
    },

    {
      headerName: localizedStrings.description,
      field: 'description',
      flex: 2
    }
  ]

  const cleanUpFromGroups = async documents => {
    //remove reference to all docs from groups
    for (var doc of documents) {
      for (var group of groupsData) {
        if (group.documents && group.documents.includes(doc.id)) {
          await GroupsApi.removeGroupDocOverride(instance, accounts, doc.id, group.id)
        }
      }
    }
  }

  const cleanUpFromPackages = async documents => {
    //remove reference to all docs from packages
    for (var doc of documents) {
      await DocumentsApi.removeDocRefFromPackages(instance, accounts, doc.id)
    }
  }

  const multiDeleteDocuments = async documents => {
    setDisableButtons(true)
    Promise.all([cleanUpFromGroups(documents), cleanUpFromPackages(documents)])
      .then(async () => {
        enqueueSnackbar('Successfully removed all documents from group overrides and packages', SnackbarVariants.SUCCESS)
        await Promise.all(documents.map(doc => DocumentsApi.deleteDocument(instance, accounts, doc)))
          .then(async () => enqueueSnackbar('Successfully deleted all documents', SnackbarVariants.SUCCESS))
          .catch(error => enqueueSnackbar(`Error deleting document`, SnackbarVariants.ERROR))
      })
      .catch(error => enqueueSnackbar(`Error removing document from group overrides and packages`, SnackbarVariants.ERROR))
    setDisableButtons(false)
    await refetchDocuments()
    onClose()
  }

  return (
    <SlideUpDialog
      id='multiDeleteModal'
      open={open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>Delete Documents</h3>}
      actions={
        <DeleteButton
          id='multiDeleteButton'
          style={{ width: 100 }}
          disabled={disableButtons}
          onClick={async () => await multiDeleteDocuments(deleteDocuments)}
        >
          {localizedStrings.delete}
        </DeleteButton>
      }
    >
      <DialogContent>
        <h3 style={{ margin: '0px', marginBottom: '20px' }}>{localizedStrings.deleteAllMessage}</h3>
        <CustomDataGrid
          rows={deleteDocuments ? deleteDocuments : []}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={p => setPageSize(p.pageSize)}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      </DialogContent>
    </SlideUpDialog>
  )
}
