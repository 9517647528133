import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class CrmProductsApi {
  getCrmProducts = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.documentsURL}/crmproducts`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Crm Products Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Crm Product may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  getCrmProduct = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.documentsURL}/crmproducts/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Crm Product Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Crm Product may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  createCrmProduct = async (msalInstance, accounts, crmProduct) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .post(`${env.urls.documentsURL}/crmproducts`, crmProduct, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Create crmProduct Failed!')
        console.log(`Sent:\n${JSON.stringify(crmProduct)}`)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  updateCrmProduct = async (msalInstance, accounts, crmProduct) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .put(`${env.urls.documentsURL}/crmproducts`, crmProduct, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Update crmProduct Failed!')
        console.log(`Sent:\n${JSON.stringify(crmProduct)}`)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }
  deleteCrmProduct = async (msalInstance, accounts, crmProductId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .delete(`${env.urls.documentsURL}/crmproducts/${crmProductId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => true)
      .catch(error => {
        console.log('Delete crmProduct Failed!')
        if (error.response) {
          console.log(`Sent:\n${crmProductId}`)
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 409) {
            throw new Error('A document requires the crmProduct')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new CrmProductsApi()
  }
  return instance
}

export default getInstance()
