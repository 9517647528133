import { Tab, Tabs } from '@mui/material'
import { useHistory } from 'react-router'
import '../index.css'
import { useWtxLocalization, WtxColors } from '@wavetronix/common-components'

const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    minWidth: '190px'
  }
}

export default function PageNavbar(props) {
  const history = useHistory()
  let localizedStrings = useWtxLocalization()

  const onChange = path => {
    if (window.location.pathname !== `/${path}`) {
      history.push(`/${path}`)
    }
    props.onChangeCallback(path)
  }

  return (
    <Tabs
      value={props.value}
      TabIndicatorProps={{
        sx: {
          backgroundColor: WtxColors.IQ_BLUE
        }
      }}
      orientation='vertical'
      sx={classes.tabParent}
    >
      <Tab id='documentsTab' sx={classes.tab} label={localizedStrings.documents} onClick={() => onChange('documents')} />
      <Tab id='searchTab' sx={classes.tab} label={localizedStrings.search} onClick={() => onChange('search')} />
      <Tab id='usersTab' sx={classes.tab} label={localizedStrings.users} onClick={() => onChange('users')} />
      <Tab id='categoriesTab' sx={classes.tab} label={localizedStrings.categories} onClick={() => onChange('categories')} />
      <Tab id='crmProductsTab' sx={classes.tab} label={'CRM Products'} onClick={() => onChange('crmproducts')} />
      <Tab id='assetsTab' sx={classes.tab} label={'Assets'} onClick={() => onChange('assets')} />
    </Tabs>
  )
}
