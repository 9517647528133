import { useMsal } from '@azure/msal-react'
import { FormControl, TextField } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { CustomSelect, PrimaryButton, SnackbarVariants, useWtxLocalization, SlideUpDialog } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import UsersApi from '../../api/UsersApi'
import { env } from '../../index.js'
const classes = {
  paper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '16px 32px 24px'
  },
  userInfo: {
    marginTop: '15px'
  }
}

export default function UserModal(props) {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [accessLevel, setAccessLevel] = useState('')
  const { instance, accounts } = useMsal()
  let localizedStrings = useWtxLocalization()

  async function updateUser(user, accessLevel) {
    await UsersApi.updateUserAccess(instance, accounts, user.id, accessLevel)
  }

  useEffect(() => {
    if (props.user && props.user.documentsAccessLevel && props.user.documentsAccessLevel[env.basicAuthentication.fromCompany]) {
      setAccessLevel(props.user.documentsAccessLevel[env.basicAuthentication.fromCompany])
    }
  }, [props.user])

  const onClose = () => (buttonsDisabled ? null : props.onClose())

  return (
    <SlideUpDialog
      id='userModal'
      maxWidth={'sm'}
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>{localizedStrings.manageUsers}</h3>}
      actions={
        <PrimaryButton
          id='userChangesSaveButton'
          style={{ marginRight: 15, width: 100 }}
          disabled={props.user.accessLevel === accessLevel || buttonsDisabled}
          onClick={async () => {
            setButtonsDisabled(true)
            let key = enqueueSnackbar(localizedStrings.snackbar.updatingUser, SnackbarVariants.LOADING)
            await updateUser(props.user, accessLevel)
              .then(async () => {
                await props.refetch()
                closeSnackbar(key)
                enqueueSnackbar(localizedStrings.snackbar.updatedUser, SnackbarVariants.SUCCESS)
              })
              .catch(() => {
                closeSnackbar(key)
                enqueueSnackbar(localizedStrings.snackbar.updatedUserFailed, SnackbarVariants.ERROR)
              })
              .finally(() => {
                setButtonsDisabled(false)
                props.onClose()
              })
          }}
        >
          {localizedStrings.save}
        </PrimaryButton>
      }
    >
      <DialogContent>
        <TextField
          id='userFirstNameTextField'
          sx={classes.userInfo}
          size='small'
          label={localizedStrings.firstName}
          style={{ width: '100%' }}
          value={props.user.givenName}
          disabled
        />
        <TextField
          id='userLastNameTextField'
          sx={classes.userInfo}
          size='small'
          label={localizedStrings.lastName}
          style={{ width: '100%' }}
          value={props.user.surname}
          disabled
        />
        <FormControl style={{ width: '100%' }} sx={classes.userInfo}>
          <CustomSelect
            id='userAccessLevelSelect'
            label={localizedStrings.accessLevel}
            required={true}
            value={accessLevel}
            onChange={e => setAccessLevel(e.target.value)}
            options={['Basic', 'Intermediate', 'Advanced']}
          />
        </FormControl>
      </DialogContent>
    </SlideUpDialog>
  )
}
