import { TextField, Box } from '@mui/material'
import { CustomSelect, useWtxLocalization, CustomCheckbox } from '@wavetronix/common-components'
import { containsSubstring } from '../../utils/stringUtils'

export const DEFAULT_CATEGORIES_FILTER = {
  name: '',
  description: '',
  family: 'All',
  showIsArchived: false
}

export const filterCategories = (filter, pageCategory, categories, displayedProductFamilies) =>
  categories
    ? categories.filter(
        c =>
          (pageCategory === 'Product' ? displayedProductFamilies.includes(c.family) : true) &&
          (filter.showIsArchived === true || (filter.showIsArchived === false && c.isArchived === false)) &&
          c.categoryType === pageCategory &&
          (containsSubstring(c.name, filter.name) || filter.name === '') &&
          (containsSubstring(c.description, filter.description) || filter.description === '') &&
          (filter.family === c.family || filter.family === 'All')
      )
    : []

export default function CategoriesFilterDrawer({ filter, setFilter, category, categoryFamlies }) {
  let localizedStrings = useWtxLocalization()

  return (
    <>
      <TextField
        id='filterNameTextField'
        style={{ width: '100%' }}
        label={localizedStrings.name}
        size='small'
        value={filter.name}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
      />
      <TextField
        id='filterDescriptionTextField'
        style={{ marginTop: '10px', width: '100%' }}
        label={localizedStrings.description}
        size='small'
        value={filter.description}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, description: e.target.value }))}
      />
      {category === 'Product' ? (
        <CustomSelect
          id='filterProductFamilySelect'
          style={{ width: '100%', marginTop: '15px' }}
          label={localizedStrings.productFamily}
          value={filter.family}
          onChange={e => setFilter(f => ({ ...f, family: e.target.value }))}
          options={['All', ...categoryFamlies.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1)).map(p => p.name)]}
        />
      ) : (
        <span />
      )}
      {category === 'Product' || category === 'Product Family' ? (
        <Box sx={{ display: 'flex', direction: 'row' }}>
          <CustomCheckbox
            id='filterArchivedCheckbox'
            checked={filter.showIsArchived}
            onChange={e => setFilter(f => ({ ...f, showIsArchived: e.target.checked }))}
          />
          <span style={{ paddingTop: '9px' }}>Show Archived</span>
        </Box>
      ) : null}
    </>
  )
}
