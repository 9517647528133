import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class UsersApi {
  getUsers = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  updateUserAccess = async (msalInstance, accounts, id, accessLevel) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .put(`${env.urls.gatekeeperURL}/users/docaccess/${id}?accessLevel=${accessLevel}`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new UsersApi()
  }
  return instance
}

export default getInstance()
