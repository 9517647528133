import { React, useState, useMemo } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { env } from '../../index.js'
import {
  acquireAccessToken,
  CenteredDiv,
  ErrorMessage,
  parseJwt,
  RegularButton,
  useWtxLocalization,
  WtxColors,
  SnackbarVariants,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import DocumentsApi from '../../api/DocumentsApi'
import CategoriesApi from '../../api/CategoriesApi'
import { useLocation } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'
import BackButton from '../controls/BackButton'
import { useEffect } from 'react'
import qs from 'query-string'
import DocumentTypeCard from '../controls/DocumentTypeCard'
import UploadDocumentModal from '../modals/UploadDocumentModal'
import { isRightColumn } from './DocumentsTypeDisplayPage'
import { useSnackbar } from 'notistack'
import DocumentsFilterDrawer, { DEFAULT_DOCUMENTS_FILTER, filterDocuments } from '../drawers/DocumentFilterDrawer'

export default function ProductViewerPage(props) {
  const { instance, accounts } = useMsal()
  let location = useLocation()
  let querystring = location.search.length > 0 && location.search[0] === '?' ? location.search.substring(1) : ''
  let productId = qs.parse(querystring).id
  let productName = qs.parse(querystring).name
  let fromFamily = qs.parse(querystring).from

  const [fileName, setFileName] = useState('')
  const [downloadToken, setDownloadToken] = useState('')
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false)
  const [firstColumn, setFirstColumn] = useState(null)
  const [secondColumn, setSecondColumn] = useState(null)
  const [filter, setFilter] = useState(DEFAULT_DOCUMENTS_FILTER)
  const [reorderMap, setReorderMap] = useState({})
  const [changedDocs, setChangedDocs] = useState(new Set())
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  let localizedStrings = useWtxLocalization()

  const [categoryOptions, setCategoryOptions] = useState(null)

  const { data: categoryData } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => await CategoriesApi.getCategories(instance, accounts)
  })

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['documents'],
    queryFn: async () => await DocumentsApi.getMyDocuments(instance, accounts),
    select: data => data.filter(d => d.products.filter(p => p === productId).length > 0)
  })

  const productTypes = useMemo(() => {
    let res = []
    if (categoryData) {
      res =
        filter.showIsArchived === false
          ? categoryData.filter(c => c.categoryType === 'Product' && c.isArchived === false)
          : categoryData.filter(c => c.categoryType === 'Product')
    }
    return res.sort((a, b) => (a.order > b.order ? 1 : -1))
  }, [filter, categoryData])

  useEffect(() => {
    if (
      productId &&
      categoryOptions &&
      categoryOptions[productId] &&
      categoryOptions[productId].localization &&
      categoryOptions[productId].localization[window.navigator.language]
    ) {
      setFileName(categoryOptions[productId].localization[window.navigator.language])
    } else {
      setFileName(qs.parse(querystring).name)
    }
  }, [productId, categoryOptions, querystring])

  useEffect(() => {
    if (categoryData && categoryData.length !== 0) {
      setCategoryOptions(
        categoryData.reduce((map, value) => {
          map[value.id] = value
          return map
        }, {})
      )
    }
  }, [categoryData])

  useEffect(() => {
    if (data && categoryOptions) {
      let docOrder = {}
      let eachDocOrder = {}
      let docMap = {}
      for (let doc of filterDocuments(
        filter,
        data,
        productTypes.map(p => p.id)
      )) {
        //logic for doc map
        if (docMap[doc.documentType]) {
          docMap[doc.documentType].documents.push(doc)
        } else {
          docMap[doc.documentType] = {
            documents: [doc],
            name: categoryOptions[doc.documentType] ? categoryOptions[doc.documentType].name : doc.documentType
          }
        }

        eachDocOrder[doc.id] = doc

        //logic for docOrder
        if (!docOrder[doc.documentType]) {
          docOrder[doc.documentType] = categoryOptions[doc.documentType].order
        }
      }

      let documents = Object.entries(docMap).map(d => {
        return {
          id: d[0],
          name: d[1].name,
          docs: d[1].documents.sort((a, b) => {
            if (a.order > b.order) {
              return 1
            } else {
              return -1
            }
          })
        }
      })
      setFirstColumn(documents.filter(d => !isRightColumn(d.name)).sort((a, b) => (docOrder[a.id] > docOrder[b.id] ? 1 : -1)))
      setSecondColumn(documents.filter(d => isRightColumn(d.name)).sort((a, b) => (docOrder[a.id] > docOrder[b.id] ? 1 : -1)))
      setReorderMap(eachDocOrder)
    }
  }, [filter, data, productTypes, categoryOptions])

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }

    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      resetDownloadToken()
    }
  }, [instance, accounts, downloadToken])

  const updateOrder = async () => {
    let key = enqueueSnackbar(localizedStrings.snackbar.docInfoUpdate, {
      variant: 'default',
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      action: <CircularProgress />,
      persist: true
    })
    let tasks = []
    for (let changed of changedDocs) {
      tasks.push(DocumentsApi.updateInfo(instance, accounts, reorderMap[changed]))
    }

    await Promise.all(tasks)
      .then(async () => {
        enqueueSnackbar(localizedStrings.snackbar.docsInfoUpdated, SnackbarVariants.SUCCESS)
      })
      .catch(async () => {
        enqueueSnackbar(localizedStrings.snackbar.docsInfoUpdateFailed, SnackbarVariants.ERROR)
      })
      .finally(() => {
        refetch()
        closeSnackbar(key)
      })
  }

  if (error) {
    return (
      <div>
        <BackButton
          previous={`to Documents`}
          path={`../documents?family=${fromFamily}`}
          onClick={() => {
            setFirstColumn(null)
            setSecondColumn(null)
          }}
        />
        <CenteredDiv>
          <ErrorMessage error={error} />{' '}
        </CenteredDiv>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div>
        <BackButton
          previous={`to Documents`}
          path={`../documents?family=${fromFamily}`}
          onClick={() => {
            setFirstColumn(null)
            setSecondColumn(null)
          }}
        />
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      </div>
    )
  } else {
    return (
      <>
        <UploadDocumentModal
          open={documentUploadModalOpen}
          onClose={() => {
            setDocumentUploadModalOpen(false)
          }}
          styles={props.styles}
          refetch={refetch}
          productId={productId}
        />
        {data.length <= 0 ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <BackButton
                  previous={`to Documents`}
                  path={`../documents?family=${fromFamily}`}
                  onClick={() => {
                    setFirstColumn(null)
                    setSecondColumn(null)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <h1 style={{ textAlign: 'center' }}>{fileName}</h1>
              </Grid>
              <Grid item xs={3}>
                <div style={{ display: 'flex', float: 'right' }}>
                  <RegularButton
                    id='docUploadModalOpenButton'
                    style={{ marginRight: '16px', marginTop: 15, height: 40.5, width: 180 }}
                    onClick={() => setDocumentUploadModalOpen(true)}
                  >
                    {localizedStrings.uploadDoc}
                  </RegularButton>
                </div>
              </Grid>
            </Grid>
            <div style={{ marginTop: '-24px', marginBottom: '-16px' }}></div>
            <div>
              <CenteredDiv>
                <div
                  style={{
                    margin: 'auto',
                    textAlign: 'center',
                    padding: 15,
                    marginTop: 15,
                    background: WtxColors.GOLD,
                    width: '80%',
                    height: '60px',
                    borderRadius: '1.25rem'
                  }}
                >
                  <h4
                    style={{
                      marginTop: '3px',
                      marginBottom: '10px'
                    }}
                  >{`${localizedStrings.adminNoDocs.info} ${fileName}.`}</h4>
                  <div style={{ fontSize: '14px' }}>{`${localizedStrings.adminNoDocs.prompt}`}</div>
                </div>
              </CenteredDiv>
            </div>
          </>
        ) : (
          <PersistantFilterDiv
            defaultOpen={false}
            page={
              <>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <BackButton
                      previous={`to Documents`}
                      path={`../documents?family=${fromFamily}`}
                      onClick={() => {
                        setFirstColumn(null)
                        setSecondColumn(null)
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h1 style={{ textAlign: 'center' }}>{fileName}</h1>
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ display: 'flex', float: 'right' }}>
                      <RegularButton
                        id='docUploadModalOpenButton'
                        style={{ marginRight: '16px', marginTop: 15, height: 40.5, width: 180 }}
                        onClick={() => setDocumentUploadModalOpen(true)}
                      >
                        {localizedStrings.uploadDoc}
                      </RegularButton>
                      <RegularButton
                        id='docsOrderSaveButton'
                        style={{ marginRight: '16px', marginTop: 15, height: 40.5, width: 180 }}
                        onClick={updateOrder}
                      >
                        Save Order
                      </RegularButton>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ marginTop: '-24px', marginBottom: '-16px' }}></div>
                <div>
                  <>
                    <CenteredDiv>
                      <div style={{ width: '65%', marginBottom: '100px', display: 'flex' }}>
                        <div style={{ width: '50%' }}>
                          {firstColumn
                            ? firstColumn.map(doc => {
                                return (
                                  <div key={doc.id} style={{ margin: '0px 30px 60px 0px' }}>
                                    <DocumentTypeCard
                                      documentsCategory={
                                        categoryOptions &&
                                        categoryOptions[doc.id] &&
                                        categoryOptions[doc.id].localization &&
                                        categoryOptions[doc.id].localization[window.navigator.language]
                                          ? categoryOptions[doc.id].localization[window.navigator.language]
                                          : doc.name
                                      }
                                      documents={doc.docs}
                                      token={downloadToken}
                                      reorderMap={reorderMap}
                                      setReorderMap={(id, value) => {
                                        setChangedDocs(changedDocs => new Set([...changedDocs, id]))
                                        setReorderMap(oldMap => ({ ...oldMap, [id]: value }))
                                      }}
                                      productId={productId}
                                      productName={productName}
                                    />
                                  </div>
                                )
                              })
                            : []}
                        </div>
                        <div style={{ width: '50%' }}>
                          {secondColumn
                            ? secondColumn.map(doc => {
                                return (
                                  <div key={doc.id} style={{ margin: '0px 0px 60px 30px' }}>
                                    <DocumentTypeCard
                                      documentsCategory={
                                        categoryOptions &&
                                        categoryOptions[doc.id] &&
                                        categoryOptions[doc.id].localization &&
                                        categoryOptions[doc.id].localization[window.navigator.language]
                                          ? categoryOptions[doc.id].localization[window.navigator.language]
                                          : doc.name
                                      }
                                      documents={doc.docs}
                                      token={downloadToken}
                                      reorderMap={reorderMap}
                                      setReorderMap={(id, value) => {
                                        setChangedDocs(changedDocs => new Set([...changedDocs, id]))
                                        setReorderMap(oldMap => ({ ...oldMap, [id]: value }))
                                      }}
                                      productId={productId}
                                      productName={productName}
                                    />
                                  </div>
                                )
                              })
                            : []}
                        </div>
                      </div>
                    </CenteredDiv>
                  </>
                </div>
              </>
            }
            drawer={<DocumentsFilterDrawer filter={filter} setFilter={setFilter} options={['All']} justDocType={true} />}
            resetFilter={() => setFilter(DEFAULT_DOCUMENTS_FILTER)}
          />
        )}
      </>
    )
  }
}
